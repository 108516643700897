import React, { Component } from 'react'
import { qs, qsa, setBodyFixed } from '../utils/lib'
import { gsap, Expo } from 'gsap'
import Store from './store'
import Tabs from './react_tabs'
import Share from './share'

export default class ProductImage extends Component {

  state = {
    product: this.props.product,
    ...Store.getProps(['wishlist']),
    add_cta: "Add to wishlist",
    added_cta: "In wishlist",
    cta: this.props.t.wish,
    added: false
  }

  async componentDidMount() {
    Store.linkState(this, ['wishlist'])
    if (localStorage.getItem('wishlist')) {
      await this.setState({
        wishlist: JSON.parse(localStorage.getItem('wishlist'))
      })
      Store.set("wishlist", this.state.wishlist)
    }

    this.checkWish()
  }

  checkWish() {
    let cartItems = this.state.wishlist
    let ext = cartItems.find(element => element.product_id == this.state.product.product_id);
    console.log(cartItems)
    console.log(ext)
    if (ext) {
      this.setState({
        added: true,
        cta: this.state.added_cta
      })
    }
  }
  async add(e) {
    if (this.state.added) return
    this.setState({
      cta: this.state.added_cta
    })

    if (typeof gtag !== 'undefined') {
      gtag('event', 'add', {
        'event_category': 'wishlist'
      });
    }

    let itemToAdd = this.state.product


    let cartItems = this.state.wishlist
    cartItems.push(itemToAdd)

    this.setState({
      wishlist: cartItems
    })
    Store.set("wishlist", cartItems)

    localStorage.setItem('wishlist', JSON.stringify(this.state.wishlist));

  }




  render() {
    return (
      <button className="was_btn white block" onClick={this.add.bind(this)}>
        <span data-text={this.state.cta} >{this.state.cta}</span>
      </button>
    )
  }
}
