/* eslint-disable */

import TweenLine from 'gsap'
import { Expo }  from 'gsap'
import preventScroll from 'prevent-scroll'

// promisified set state for react
export const setState = (scope, props) => new Promise(resolve => scope.setState(props, resolve))

export const isTouchDevice = () => ('ontouchstart' in window ) || navigator.maxTouchPoints
export const isHTMLElement = x => x instanceof HTMLElement
export const isString   = x => typeof x === "string"
export const isImageURL = x => isString(x) && /\.(png|jpe?g)$/ig.test(x)
export const isHexColor = x => isString(x) && /^#.+/i.test(x)
export const isFunc = fn => typeof fn === 'function'
export const isUndefined = x => typeof x === "undefined"
export const sameInstance = (a, b) => a.constructor.name === b.constructor.name
export const hasProperty = (obj, prop) => Object.prototype.hasOwnProperty.call(obj, prop)
export const qs = (selector, ctx = document) => ctx.querySelector(selector)
export const qsa = (selector, ctx = document) => Array.from(ctx.querySelectorAll(selector))

export const toggleClass = (el, className) => {
  el.classList.toggle(className)
}

export const addClass = (el, className) => {
  el.classList.add(className)
}

export const removeClass = (el, className) => {
  el.classList.remove(className)
}

export function isMobile() {
  if( navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
    ){
    return true;
  }
 else {
    return false;
  }
}

export const IsWebGLSupported = function () {
  var canvas = document.createElement("canvas");
  var gl = canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
  return gl && gl instanceof WebGLRenderingContext
}

export const deg2rad = deg => deg * Math.PI / 180
export const rad2deg = deg => deg * Math.PI / 180

export const parameterize = str => str.trim().toLowerCase().replace(/[^a-z0-9\-_]+/g, "-")
export const setProtocolToURL = x => window.location.protocol + x.replace(/https?:/ig, '')

// you can use it with await sleep(500)
// time is in ms
export const sleep = (time) => new Promise(res => {
  setTimeout(() => res(true), time)
})

// Crossbrowser window scrolltop
export const getScrollTop = function () {
    if(typeof pageYOffset!= 'undefined'){
        //most browsers
        return pageYOffset;
    }
    else{
        var B= document.body; //IE 'quirks'
        var D= document.documentElement; //IE with doctype
        D= (D.clientHeight) ? D : B ;
        return D.scrollTop;
    }
}

// load image with promise
export const loadImage = (src) => {
  return new Promise((resolve, reject) => {
    const image = new Image()
        image.crossOrigin = "anonymous"
          image.src = src
          image.onload  = () => resolve(image, src)
          image.onerror = () => reject(src)
  })
}

// load images with promise
export const loadImages = (sources) => {
  return Promise.all( sources.map(src => loadImage(src)) )
}

// promisified tweenmax
// with 3 arguments it will perform a TweenMax.to(), with 4, a TweenMax.fromTo
export const animate = (el, time, props, toProps = null) => {
  return new Promise(resolve => {
    const to = {
      ...( toProps || props ),
      onComplete: resolve
    }
    if ( toProps !== null )
      TweenLite.fromTo(el, time, props, to)
    else
      TweenLite.to(el, time, to)
  })
}

// Get json from api url with promise
export const fetchJSON = (url, props) => {
  return new Promise((res, rej) => {
    fetch(url, props)
    .then(response => response.json())
    .then(data => res(data))
    .catch(err => rej(err))
  })
}

export class Cookie {

  static create(name,value,days) {
      if (days) {
          var date = new Date();
          date.setTime(date.getTime()+(days*24*60*60*1000));
          var expires = '; expires='+date.toGMTString();
      }
      else var expires = '';
      document.cookie = name+'='+value+expires+'; path=/';
  }

  static get(cname) {
      var name = cname + '=';
      var ca = document.cookie.split(';');
      for(var i=0; i<ca.length; i+=1) {
          var c = ca[i];
          while (c.charAt(0) === ' ') c = c.substring(1);
          if (c.indexOf(name) === 0) return c.substring(name.length,c.length);
      }
      return "";
  }

}

/* Classes util for react with conditionals
   Example:
   <ReactComponent
      className={classes(
        'yourclass', 'anotherclass',
        { conditionalclass: YourCondition, anotherConditional: array.includes(item) }
      )}
    />
*/
export const classes = function classes(...args) {
  const res = []
  for ( let cl of args ) {
    if (typeof cl === "string") {
      res.push(cl)
    }
    else if ( typeof cl === "object" ) {
      for ( let k in cl ) {
        if ( cl[k] ) {
          res.push(k)
        }
      }
    }
  }
  return res.join(" ")
}

export class Observer {
  constructor() {
    this._events = {}
  } 

  _getEvents(event) {
    this._events[event] = this._events[event] || []
    return this._events[event]
  }

  emit(event, ...args) {
    const events = this._getEvents(event)
    for (let i = 0, l = events.length; i < l; i += 1) {
      events[i](...args)
    }
  }

  client() {
    return {
      on: this.on.bind(this),
      off: this.off.bind(this),
    }
  }

  on(event, fn) {
    const events = this._getEvents(event)
    if ( typeof fn === "function" ) {
      events.push(fn)
    }
  }

  off(event, fn) {
    const events = this._getEvents(event)
    const index  = events.indexOf(fn)
    if ( index > -1 ) {
      events.splice(1, index)
      return true
    }
    return false
  }
}

export const getRandomInit = (min, max) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min)) + min
}

export function map(value, low1, high1, low2, high2) {
	return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
}

export function viewportSize() {
  return {
    w: window.innerWidth,
    h: window.innerHeight,
    ratio: window.innerWidth / window.innerHeight
  }
}

export function inViewport(e) {
  const { height, bottom, top, left, right, width } = e.getBoundingClientRect()
  const h = height || (bottom - top)
  const w = width || (right - left)
  const viewport = viewportSize()

  if (!h || !w) return false
  if (top > viewport.h || bottom < 0) return false
  if (right < 0 || left > viewport.w) return false

  return true
}

export function pos(base, range, relY, offset) {
  return base + limit(0, 1, relY - offset) * range;
}

export function limit(min, max, value) {
  return Math.max(min, Math.min(max, value));
}

export const setBodyFixed = (val) => {
  console.log('ooo',val)
  if (val) {
    preventScroll.on()
    addClass(document.body, 'is-fixed')
  } else {
    preventScroll.off()
    removeClass(document.body, 'is-fixed')
  }
}

export function lerp(val, max, min) {
  return (val - min) / (max - min)
}

export function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}
