import { qs,qsa } from '../utils/lib'
import { gsap, Expo } from 'gsap'
import images from './lazyload'
import _ from 'lodash'

export default class productFilters {
    constructor(el) {
       this.wrapper = el
       this.openButton = el.querySelector('.main_button')
       this.container = el.querySelector('.products_filters_container')
       this.height = 0
       this.actions = el.querySelector('.js-actions')
       this.active = false
       this.animating = false
       this.filters_btns = qsa('.data-filter',el)
       this.sort_btns = qsa('.data-sort',el)
       this.filters_cats = []
       this.filters_cols = []
       this.filters_patterns = []
       this.filters_sizes = []
       this.filtered = []
       this.apply_btn = qs('.js-apply-filters',el)
       this.clear_btn = qs('.js-clear-filters',el)
       this.products_cont = qs('.js-products-container')
       this.products = qsa('.js-product-index')
       this.sort_order = 'newest'
       this.default_sort = 'newest'
       this.input_category = qs("#product_category")
       this.input_color = qs("#product_color")
       this.input_patterns = qs("#product_pattern")
       this.input_size = qs("#product_size")
       this.ordering = qs('#ordering')
       this.form = qs(".products_filters_container_grid")
    }

    init() {
        this.openButton.addEventListener("click",() => {
            this.open()
        })
        this.filters_btns.forEach((el) => {
            el.addEventListener("click",() => {
                this.toggleFilter(el)
            })
        })
        this.sort_btns.forEach((el) => {
            el.addEventListener("click",() => {
                this.toggleSort(el)
            })
        })
         this.apply_btn.addEventListener("click", () => {
            this.apply()
         })

         this.clear_btn.addEventListener("click", () => {
            this.clear()
         })
         const filtered = qsa('.data-filter.selected',this.wrapper)
         if ( filtered.length > 0 ) {
            let filters = []
            filtered.forEach((f) => {
                filters.push(f.querySelector("p").textContent)
            })
            const t = document.querySelector('.filter_target')
            if ( t ) {
                t.innerHTML = filters.join(", ")
            }
         }
    }

    toggleFilter(el) {
        el.classList.toggle("selected")
    }

    toggleSort(el) {
        this.sort_btns.forEach((srt) => {
            if ( el == srt ) {
                srt.classList.add("selected")
            } else {
                srt.classList.remove("selected")
            }
        })

        this.sort_order = qs('.data-sort.selected').getAttribute('data-value')
    }

    clear() {

        this.filters_btns.forEach((el) => {
            el.classList.remove("selected")
        })
         this.products.forEach((p) => {
            p.classList.remove("product_hidden")
        })
        
    }
    apply() {
        this.applyFilters()
    }

    applySort() {
        this.sort_order = qs('.data-sort.selected').getAttribute('data-value')
        if ( this.sort_order == 'newest') {
            this.products = _.sortBy(this.products, [function(o) { return parseInt(o.dataset.position) }])
        
        }
        if ( this.sort_order == 'price_ASC') {
            this.products = _.sortBy(this.products, [function(o) { return parseInt(o.dataset.price) }])
        }
        if ( this.sort_order == 'price_DESC') {
            this.products = _.sortBy(this.products, [function(o) { return parseInt(o.dataset.price) }]).reverse()
        }
        this.products_cont.innerHTML = ""
        this.products.forEach((el) => {
            this.products_cont.appendChild(el)
        })

    }
    applyFilters() {
        const filtered = qsa('.data-filter.selected',this.wrapper)
        this.filters_cats = []
        this.filters_cols = []
        this.filters_sizes = []
        this.filters_patterns = []
        this.filtered = []
        filtered.forEach((el) => {
            if ( el.getAttribute('data-filter') == 'category' ) {
                this.filters_cats.push(el.getAttribute('data-value'))
            } 
            else if ( el.getAttribute('data-filter') == 'color' ) {
                this.filters_cols.push(parseInt(el.getAttribute('data-value')))
            } 
            else if ( el.getAttribute('data-filter') == 'pattern' ) {
                this.filters_patterns.push(parseInt(el.getAttribute('data-value')))
            } else {
                this.filters_sizes.push(el.getAttribute('data-value'))
            }
        })
        this.input_category.value = this.filters_cats.join(",")
        this.input_color.value = this.filters_cols.join(",")
        this.input_patterns.value = this.filters_patterns.join(",")
        this.input_size.value = this.filters_sizes.join(",")
        this.ordering.value = this.sort_order
        this.form.submit()
        
    }


    open() {
        if ( this.animating ) { return }
        this.animating = true
        if ( this.active ) {
            this.actions.classList.toggle('open')
            gsap.to(this.container,{
                height: 0,
                duration: .4,
                onComplete: () => {
                    this.active = false
                    this.animating = false
                    images()
                }
            })
        } else {
            this.container.classList.add("active")
            this.height = this.container.offsetHeight
            this.container.classList.remove("active")
            this.actions.classList.toggle('open')
            gsap.to(this.container,{
                height: this.height,
                marginBottom: '80px',
                duration: .4,
                onComplete: () => {
                    this.active = true
                    this.animating = false
                    images()
                }
            })
        }
        

    }

    
}