import React, { Component } from 'react'
import { qs, qsa, setBodyFixed } from '../utils/lib'
import { gsap, Expo } from 'gsap'
import Store from './store'
import dropdown from '../images/dropdown_btn.svg';

export default class ProductImage extends Component {

	state = {
    active: 0,
    data: JSON.parse(this.props.tabs)
	}

	async componentDidMount() {
  }
  
  setActive(i) {
    this.setState({
      active: i
    })
  }

	render() {
		return (
      <div>
          <div className="product_detail_col_container_description list">
              {this.state.data.map((el,i) => (
                  <div key={i} >
                  <p className={"tabs_button default_link " + ( i == this.state.active ? "active" : "")} onClick={this.setActive.bind(this,i)}>{el.title}
                  
                  </p>
                  <div className={"tabs_body_mobile " + ( i == this.state.active ? "active" : "")} >

                    {this.state.data[this.state.active].body.split("---").map((el,i) => (
                <>
                {i == 0 &&
                  <p>{el}</p>
                }
                {i == 1 &&
                  <ul>
                    {el.split('*').map((el,i) => (
                      <li>{el}</li>
                    ))}
                  </ul>
                }
                {i == 2 &&
                  <p>{el}</p>
                }
                </>
              ))}

                  </div>
                  </div>
              ))}
              
          </div>
          <div className="product_detail_col_container_description tabs_body ">
              {this.state.data[this.state.active].body.split("---").map((el,i) => (
                <>
                {i == 0 &&
                  <p>{el}</p>
                }
                {i == 1 &&
                  <ul>
                    {el.split('*').map((el,i) => (
                      <li>{el}</li>
                    ))}
                  </ul>
                }
                {i == 2 &&
                  <p>{el}</p>
                }
                </>
              ))}
          </div>
      </div>
		)
	}
}
