import { qs, qsa, setBodyFixed, map, isMobile } from '../utils/lib'
import { gsap, Expo } from 'gsap'

export default function() {
	const els = qsa('.product_detail_col_images img')
	const container = qs('.product_zoom')
	const target= qs('.zoom-target')
	els.forEach(function(el){
		el.addEventListener("click",() => {
			const image = el.getAttribute("src")
			target.setAttribute("src",image)
			setTimeout(() => {
				container.classList.add("active")
				setBodyFixed(true)	
			},500)
			
		})
		container.addEventListener("click",() => {
			container.classList.remove("active")
			setBodyFixed(false)
		})
		container.addEventListener("mousemove",(e) => {
			if(isMobile()) return

			let y = e.clientY
			let  val = map(y, 0, window.innerHeight, 0, (target.clientHeight - window.innerHeight))

			gsap.to(target, {
				duration: .2,
				y: -val
			})
		})
	})
}