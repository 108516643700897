import React, { Component } from 'react'
import fb from '../images/facebook_black.svg';
import wp from '../images/whatsapp_black.svg';
import ml from '../images/mail_new.svg';

export default class Share extends Component {


	render() {
		return (
      <div className="share_icons">
        <p className="h4">Share: </p>
        <a href={"https://www.facebook.com/sharer/sharer.php?u=" + window.location} target="_blank" >
          <img src={fb} />
        </a>
        <a href={"https://api.whatsapp.com/send?text="+ window.location} target="_blank">
          <img src={wp} />
        </a>  
        <a href={"mailto:to@email.com?&cc=&bcc=&subject=Share from Wait and see&body=" + window.location} target='_blank'>
          <img src={ml} />
        </a>  
      </div>
		)
	}
}










