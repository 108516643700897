import React, { Component } from 'react'
import { qs, qsa, setBodyFixed } from '../utils/lib'
import { gsap, Expo } from 'gsap'
import Store from './store'
import Share from './share'
import CartItem from './product_cart'

export default class WishListRender extends Component {

  state = {
    ...Store.getProps(['wishlist']),
    saved: false
  }

  async componentDidMount() {
    if (localStorage.getItem('wishlist')) {
      await this.setState({
        wishlist: JSON.parse(localStorage.getItem('wishlist'))
      })
      Store.set("wishlist", this.state.wishlist)
    }
  }

  async remove(i) {
    let items = this.state.wishlist
    items.splice(i, 1)
    await this.setState({
      wishlist: items
    })
    console.log(this.state.wishlist)
    Store.set("wishlist", this.state.wishlist)
    localStorage.setItem('wishlist', JSON.stringify(this.state.wishlist));

  }


  render() {
    return (
      <div className="row">


        {this.state.wishlist.map((el, i) => (
          <div className="wishlist_container_item">



            <CartItem product={el} t={JSON.parse(this.props.translations)} hide_wish={true} remove_from_wish={true} remove={this.remove.bind(this, i)} />

          </div>
        ))}

      </div>
    )
  }
}