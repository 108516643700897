import React, { Component } from 'react'
import { qs, qsa, setBodyFixed } from '../utils/lib'
import { gsap, Expo } from 'gsap'
import Store from './store'
import trolley from '../images/trolley.svg'
import trolley_red from '../images/trolley_red.svg'

export default class ProductImage extends Component {

	state = {
    ...Store.getProps(['cart_count']),
    ...Store.getProps(['cart_open']),
    ...Store.getProps(['cart_items']),
    ...Store.getProps(['close_cart']),
    ...Store.getProps(['cart_total']),
    ...Store.getProps(['checkout']),
    t: JSON.parse(this.props.translations),
    animating: false,
    loading: false,
    total: 0
	}

	async componentDidMount() {
    gsap.set(this.refs.wrapper, {
      xPercent: 100,
    })
    Store.linkState(this,['cart_count'])
    Store.linkState(this,['cart_open'])
    Store.linkState(this,['cart_items'])
    Store.linkState(this,['close_cart'])
    Store.linkState(this,['cart_total'])
    Store.linkState(this,['checkout'])
    if(localStorage.getItem('cart')){
      await this.setState({
        cart_items: JSON.parse(localStorage.getItem('cart'))
      })
      Store.set("cart_items",this.state.cart_items)
    }

    this.calcTotal()

    console.log(this.state.cart_items)

  }

  toggle() {
    if(this.state.animating || this.state.cart_total == 0) return
    if ( this.state.cart_open ) {
      this.close()
    } else {
      this.open()
    }
  }
  open() {
    const icon = qs('.js-mobile-menu')
    const container = qs('.mobile_menu')
    const submenu = container.querySelectorAll('.mobile_menu_container.closed') 
    const html = qs('html')
    container.classList.remove('opened')
    container.classList.add('closed')
    html.classList.add('is_hidden')
    document.body.classList.add('is_hidden')
    submenu.forEach((menu) => {
      menu.classList.remove('opened')
      menu.classList.add('closed')          
    })  
    const tl = gsap.timeline()
    this.setState({animating: true})
    tl
      .to(this.refs.wrapper, {
        xPercent: 100,
        opacity:1,
        duration:0
      })
      .to(this.refs.wrapper, {
        xPercent: 0,
        ease: Expo.easeInOut,
        duration: .6,
        onComplete: () => {
          this.setState({animating: false,cart_open: true})
        }
      })
  }

  close() {
    
    const html = qs('html')
    const tl = gsap.timeline()
    this.setState({animating: true})
    tl
      .to(this.refs.wrapper, {
        xPercent: 0,
        duration:0
      })
      .to(this.refs.wrapper, {
        xPercent: 100,
        ease: Expo.easeInOut,
        duration: .6,
        onComplete: () => {
          this.setState({animating: false,cart_open: false})
          html.classList.remove('is_hidden')
          document.body.classList.remove('is_hidden')
        }
      })
  }

  componentDidUpdate(prevProps, prevState) {
    if (Store.getProps(['add_to_cart']).add_to_cart) {
      this.open()
      Store.set("add_to_cart",false)
      this.calcTotal()
    }
      
    if ( this.state.close_cart ) {
      this.close()
      this.setState({
        close_cart: false
      })
    }
    localStorage.setItem('cart', JSON.stringify(this.state.cart_items));
    
    
  }

	componentWillUnmount() {
  }


  add(i) {
    let items = this.state.cart_items
    if ( items[i].quantity == items[i].size.qty ) {
      return
    }
    items[i].quantity += 1
    this.setState({
      cart_items: items
    })
    this.calcTotal()
  }

  remove(i) {
    let items = this.state.cart_items
    items[i].quantity -= 1
    if ( items[i].quantity == 0 ) {
          items.splice(i,1)
          this.setState({
            cart_items: items
          })
      
    } else {
      this.setState({
        cart_items: items
      })
    }

    this.calcTotal()
    
  }

  delete(i) {
    let items = this.state.cart_items
    items[i].quantity  = 0
    if ( items[i].quantity == 0 ) {
      const el = document.querySelectorAll(".c-minicart__row")[i]
      gsap.to(el,{
        duration: .4,
        opacity:0,
        onComplete: () => {
          items.splice(i,1)
          this.setState({
            cart_items: items
          })
          gsap.to(el,{
            duration:0,
            opacity:1
          })
        }
      })
      
    } 

    this.calcTotal()
    
  }

  calcPrice() {
    let priceArray = []
    let total

    this.state.cart_items.forEach((el, i) => {
      let sum = parseFloat(el.price) * el.quantity

      priceArray.push(sum)
    })

    return priceArray.reduce((a, b) => a + b, 0)
  }

  calcTotal() {
    let total = 0 
    this.state.cart_items.forEach((el, i) => {
      let sum = 1 * el.quantity
      total = total + sum
    })
    Store.set("cart_total",total)
    if ( total == 0 ) {
      this.close()
    }
  }



	render() {
		return (
      <div>
      <span>
        <button onClick={this.toggle.bind(this)}>
        <img src={this.state.cart_total == 0 ? trolley : trolley_red} alt="cart" className="main_header_navbar_col_image" />
            <span className="c-red h4">{this.state.cart_total == 0 ? '' : this.state.cart_total}</span>
        </button>
      </span>  
      <div className="shopping_bag c-minicart" ref="wrapper">
      <div className="shopping_bag_bg" >
        <div className="shopping_bag_header">
          <img src={this.props.logo} alt=""/>
          <h1 className="h3 c-red text-center upcase dwerneck">{this.state.t.title}</h1>
          <img src={this.props.logo} alt=""/>
        </div>
        <div className="shopping_bag_list">
          {this.state.cart_items.map((el, i) => (
            <div className="product_add_to_cart" key={i}>
                <div className="row">
                    <div className="product_add_to_cart_image">
                        <div className="product_big_img">
                            <div className="product_big_img_wrapper">
                                <img src={el.image} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="product_add_to_cart_specs text-left">
                        <p className="c-red h4">{el.title}</p>
                        <p className="h4 mb-4">€ {el.price}
                        </p>
                        {!el.size.size == 'U' &&
                        <p className="h4">{this.state.t.size}: {el.size.size}</p>
                        }
                        <div className="quantity">
                            <span className="minus" onClick={this.remove.bind(this,i)}>-</span>
                            <span className="number">{el.quantity}</span>
                            <span className="plus" onClick={this.add.bind(this,i)}>+</span>  
                        </div>
                        <div className="quantity">
                            <span onClick={this.delete.bind(this,i)} className="h4">{this.state.t.remove}</span>
                        </div>
                    </div>
                </div>
            </div>
          ))}
        </div>
        <div className="shopping_bag_buttons">
          <a href={this.props.checkout} className="shopping_bag_buttons_btn">
              <button className="was_btn block">
                  <span data-text="Checkout">Checkout</span>
              </button> 
      
          </a>  
          <div className="shopping_bag_buttons_btn" onClick={this.close.bind(this)}>
              <button className="was_btn block white">
                  <span data-text={this.state.t.continue}>{this.state.t.continue}</span>
              </button>   
          </div>      
        </div>
      </div>
      </div>
       </div>
		)
	}
}










