import { qs,qsa, isMobile } from '../utils/lib'
import gsap from 'gsap'
export default function() {
	const loader = qs('.loader')
	if ( loader ) {
	        gsap.to(loader,{
				duration:1,
				opacity:0,
				delay: .4,
				onComplete : () => {
					loader.remove()
				}
			})
	    
		
	}
}