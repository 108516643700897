import { qs,qsa } from '../utils/lib'
import gsap from 'gsap'

export default function() {
	const source = qsa('.js_cat_image_source')
	const target = qs('.js_cat_image_target')
	source.forEach((el) => {
		el.addEventListener("mouseover",function(){
			const img = el.getAttribute("data-target")
			target.setAttribute("src",img)
			target.parentNode.classList.add("active")
		})
		el.addEventListener("mouseleave",function(){
			target.parentNode.classList.remove("active")
		})
		el.addEventListener("mousemove",function(el){
			gsap.to(target.parentNode,{
				y: el.clientY,
				x: el.clientX,
				duration: .2
			})
		})
	})

}