import { qs, qsa, setBodyFixed, map, isMobile } from '../utils/lib'
import { gsap, Expo } from 'gsap'
import Verge from 'verge';
import images from './lazyload'
import SplitText from '../utils/split.js'

export default function() {
	images()
	const texts = qsa('.js-text')
	texts.forEach((el) => {
		  gsap.set(el, {
		    autoAlpha: 0
		  })
	})
	const btt = qs('.btop')
	btt.addEventListener("click",() => {
		window.scroll({
          top: 0, 
          left: 0, 
          behavior: "smooth"
        })
	})

	const scroll_el = qs('.product_detail_col_image')
	if ( scroll_el ) {
		scroll_el.addEventListener('scroll', function(e) {
			images()
		})
	}
	window.addEventListener('scroll', function(e) {
		images()
		texts.forEach((el) => {
			  revealText(el)
		})
		if ( window.pageYOffset > window.innerHeight ) {
			btt.classList.add("visible")
		} else {
			btt.classList.remove("visible")
		}
	
	})

	window.addEventListener('resize', function(e) {
		images()
	
	})

	

	function revealText(el) {
		  if ( Verge.inY(el, -100) && !el.animated ) {
		  	      el.animated = true
		  		  const tl = new gsap.timeline()
				  const split = new SplitText(el, {type: 'words,chars'})

				  tl
				    .set(split.chars, {
				      autoAlpha: 0,
				      x: 16
				    })
				    .set(el, {
				      autoAlpha: 1
				    })
				    .staggerTo(split.chars, 1, {
				      autoAlpha: 1,
				      x: 0,
				      ease: Expo.easeOut,
				    }, 0.02)
				    .add('finish')
				    .add(() => {split.revert()}, 'finish+=0.5')
		  }
		  
	}
	
}