import { qs,qsa } from '../utils/lib'
import ProductZoom from './zoom.js'
import { gsap, CSSPlugin, ScrollToPlugin, Draggable } from "gsap/all"; 

export default class productImage {
    constructor(el) {
        const scrollPlugin = ScrollToPlugin;
        this.active = 0
        this.container = el
        this.thumbs = qsa('.js-product-thumbs')
        this.active_image = qs('.main_image',el)
        this.images = qsa('.product_big_img',el)
        this.images_cont = qs('.js-product-image')
    }

    init() {
        this.thumbs.forEach((t,i) => {
            t.addEventListener("click",() => {
                this.active = i
                this.setActive()
            })
        })
        ProductZoom()
    }

    setActive(){
        const el = this.images[this.active]
        this.container.scroll({
          top: el.offsetTop, 
          left: 0, 
          behavior: "smooth"
        })
    }
}