import React, { Component } from 'react'
import { qs, qsa, setBodyFixed } from '../utils/lib'
import { gsap, Expo } from 'gsap'
import Store from './store'
import star from '../images/star.svg'
import bstar from '../images/b_star.svg'

export default class WishListStar extends Component {

	state = {
    product: this.props.product,
    ...Store.getProps(['wishlist']),
    add_cta: star,
    added_cta: bstar,
    cta: star,
    added: false
	}

	async componentDidMount() {
    Store.linkState(this,['wishlist'])
    if(localStorage.getItem('wishlist')){
      await this.setState({
        wishlist: JSON.parse(localStorage.getItem('wishlist'))
      })
      Store.set("wishlist",this.state.wishlist)
    }

    this.checkWish()
  }

  checkWish() {
     let cartItems = this.state.wishlist
     let ext = cartItems.find(element => element.id == this.state.product.product_id);
     if ( ext ) {
        this.setState({
          added: true,
          cta: this.state.added_cta
        })
      }
  }
  async add(e) {
    if (this.state.added) return
    this.setState({
      cta: this.state.added_cta
    }) 


    let itemToAdd = this.state.product


    let cartItems = this.state.wishlist
    cartItems.push(itemToAdd)

    this.setState({
      wishlist: cartItems
    })
    Store.set("wishlist",cartItems)

    localStorage.setItem('wishlist', JSON.stringify(this.state.wishlist));

  }


 

	render() {
		return (
        <img src={this.state.cta} onClick={this.add.bind(this)} alt="whislist button" />
        
		)
	}
}
