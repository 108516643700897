import React, { Component } from 'react'
import { qs, qsa, setBodyFixed } from '../utils/lib'
import { gsap, Expo } from 'gsap'
import Store from './store'
import CartItem from './product_cart'

export default class MiniCartList extends Component {

	
  state = {
    products: JSON.parse(this.props.products),
    t: JSON.parse(this.props.translations)
  }

 

	render() {
		return (
        <div>
          {this.state.products.map((el) => (
            <CartItem product={el} t={this.state.t} hide_wish="0" />
          ))}
        </div>
        
		)
	}
}
