import { qs, qsa } from '../utils/lib'
import gsap from 'gsap'

export default class Accordion {
  constructor(el) {
    this.wrapper = el
    this.buttons  = qsa('.accordion_button',el)
  }

  init() {
    this.buttons.forEach((el) => {
      el.addEventListener("click",this.toggleAccordion.bind(this,el))
    })
 
  }

  toggleAccordion(el) {
      const elem = el.nextElementSibling
      const dropdown = el.querySelector('.dropdown')

      if (!elem.classList.contains('open')) {
        elem.classList.add("open")
        dropdown.classList.add('active')        
      } else {
          elem.classList.remove('open')
          dropdown.classList.remove('active')        
      }
  }
}