import React, { Component } from 'react'
import icon from '../images/icon.svg'
import { fetchJSON, setCookie } from '../utils/lib'
import countryList from 'react-select-country-list'

export default class NlWidget extends Component {

  state = {
    form_status: 'disabled',
    form_text: 'Subscribe and see',
    form_checked: false,
    country: null,
    countries: countryList().getData(),
    bday: null,
    error: '',
    t: this.props.t || JSON.parse(this.props.translations)
  }

  componentDidMount() {
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  async toggleCheck(e) {
    e.target.classList.toggle('checked')
    if (e.target.classList.contains('checked')) {
      await this.setState({
        form_checked: true
      })
    } else {
      await this.setState({
        form_checked: false
      })
    }
    this.handleChange()
  }

  handleChange() {
    const mail = this.validateEmail(this.refs.email.value)
    const check = this.state.form_checked
    const names = this.refs.first_name.value.length > 0 && this.refs.last_name.value.length > 0

    if (mail && check && this.state.country && names) {
      this.setState({ form_status: 'enabled' })
    } else {
      this.setState({ form_status: 'disabled' })
    }

  }

  async submit(e) {
    e.preventDefault()
    e.stopPropagation()
    if (this.state.form_status == 'disabled') {
      const mail = this.validateEmail(this.refs.email.value)
      const check = this.state.form_checked
      const country = this.state.country
      const fname = this.refs.first_name.value.length > 0
      const lname = this.refs.last_name.value.length > 0
      const bday = this.refs.bday.value.length > 0


      if (!fname || !lname) {
        this.setState({
          error: 'Please insert name and last name'
        })
        return false
      }

      if (!fname || !lname) {
        this.setState({
          error: 'Please insert name and last name'
        })
        return false
      }
      if (!check) {
        this.setState({
          error: 'Please accept privacy'
        })
        return false
      }
      if (!country) {
        this.setState({
          error: 'Please select a country'
        })
        return false
      }
      if (!mail) {
        this.setState({
          error: 'Missing or invalid e-mail'
        })
        return false
      }
      if (!bday) {
        this.setState({
          error: "Missing birthday"
        })
        return false
      }

      return
    }
    const AUTH_TOKEN = document.querySelector('meta[name=csrf-token]').getAttribute('content');
    const url = '/eu/en/subscribe';
    const post = {
      "email": this.refs.email.value,
      "first_name": this.refs.first_name.value,
      "last_name": this.refs.last_name.value,
      "user_country": this.state.country,
      "birthday": this.refs.bday.value,
      "nickname": this.refs.nickname.value,
      "authenticity_token": AUTH_TOKEN
    }

    const fetch = await fetchJSON(url, {
      method: 'POST',
      body: JSON.stringify(post),
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'xmlhttprequest' /* XMLHttpRequest is ok too, it's case insensitive */
      }
    })
    this.setState({
      form_status: 'disabled',
      error: ''
    })

    this.refs.email.value = fetch.response == 'ok' ? "Voilà" : "Already subscribed"
    if (this.props.onSubscribe) {
      this.props.onSubscribe(fetch.response)
    } else {
      if (typeof gtag !== 'undefined') {
        gtag('event', 'submit', {
          'event_category': 'newsletter',
          'event_label': 'footer'
        });
        fbq('track', 'CompleteRegistration');
      }
      location.reload();
    }

  }

  async setCountry() {
    await this.setState({
      country: this.refs.country.value
    })
    this.handleChange()
  }



  changeType(e) {
  }
  render() {
    return (
      <div className="newsletter_widget">
        <div className="newsletter_widget_row top">

          <div className="newsletter_widget_row_input">
            <h2 className="h4 c-red text-left">{this.state.t.first_name}</h2>
            <input className="h4" type="text" ref="first_name" onChange={this.handleChange.bind(this)} />
            <h2 className="h4 c-red text-left">{this.state.t.last_name}</h2>
            <input className="h4" type="text" ref="last_name" onChange={this.handleChange.bind(this)} />
            <h2 className="h4 c-red text-left">{this.state.t.email}</h2>
            <input className="h4" type="email" ref="email" onChange={this.handleChange.bind(this)} />
            <input className="h4" id="nickname" type="text" ref="nickname" onChange={this.handleChange.bind(this)} />

          </div>
          <div className="newsletter_widget_row">
            <div className="newsletter_widget_col">
              <h2 className="h4 c-red">{this.state.t.country}</h2>
              <div className={"was_select h4 nl_sel"}>
                <select className="full" onChange={this.setCountry.bind(this)} ref="country" value={this.state.country} required>
                  <option selected value="no_country" disabled>{this.state.t.choose}</option>
                  {this.state.countries.map((el, i) => (
                    <option key={i} value={el.value}>{el.label}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="newsletter_widget_col">
              <h2 className="h4 c-red">{this.state.t.bday}*</h2>
              <input className="h4" type="date" ref="bday" required />
            </div>

          </div>

        </div>
        <br />
        <div className="newsletter_widget_row">
          <span className="newsletter_widget_row_checkbox col-span" onClick={this.toggleCheck.bind(this)}></span>
          <p className="h4 col-auto">{this.state.t.accept}</p>

        </div>
        <br />
        <div className="newsletter_widget_row">

          <button className={"was_btn small"} onClick={this.submit.bind(this)}>
            <span data-text={this.state.t.cta} >{this.state.t.cta}</span>
          </button>
          <p className="nl_error upcase h4">{this.state.error}</p>
        </div>
        <p className="super_small">{this.state.t.notice}</p>

      </div>
    )
  }
}