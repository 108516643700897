import { gsap } from 'gsap'


export default function() {
    const elements = document.querySelectorAll('[data-parallax]')

    if (elements) {
        elements.forEach((el) => {
            if (el.classList.contains("noscale")) {

            } else {
                gsap.to(el, { scale: 1.15,duration: 0 })
            }
        })
        window.addEventListener('scroll', () => {
            parallax()
        })
    }

    function parallax() {
        elements.forEach((el) => {
            const top = el.getBoundingClientRect().top

            if (top <= window.innerHeight) {
                gsap.to(el, {
                    y: - (top / 10),
                    duration: .2
                })
            }
        })
    }
}