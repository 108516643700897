import React, { Component } from 'react'
import gsap from 'gsap';
import icon from '../images/close_red.svg'
import { fetchJSON, setCookie } from '../utils/lib'
import Newsletter from './nl_widget.jsx'

export default class NlPop extends Component {

  state = {
    subscribed: false,
    t: JSON.parse(this.props.translations),
    subscribed_copy: "Thank You! You Have Been Successfully Subscribed To Our Newsletter"
  }

  componentDidMount() {
    const nl_cookie = document.cookie.indexOf('neww_nl=');
    if (nl_cookie < 0 && this.props.autoload == 1) {
      document.cookie = "neww_nl=ok; expires=Thu, 31 Dec 2025 12:00:00 UTC";
      gsap.to(this.refs.el, {
        opacity: 1,
        delay: 1
      })
      this.refs.el.classList.add("open")
    }
    const btns = document.querySelectorAll('.js_subscribe')
    btns.forEach((btn) => {
      btn.addEventListener("click", () => {
        gsap.to(this.refs.el, {
          opacity: 1
        })
        this.refs.el.classList.add("open")
      })
    })

  }


  close() {
    gsap.to(this.refs.el, {
      opacity: 0,
      onComplete: () => {

        this.refs.el.classList.remove("open")
      }
    })
  }

  submit(response) {

    if (response == 'ko') {
      this.setState({
        subscribed: true,
        subscribed_copy: "CIAO!\nYou are Already Subscribed to Our Newsletter"
      })
    } else {
      if (typeof gtag !== 'undefined') {
        gtag('event', 'submit', {
          'event_category': 'newsletter',
          'event_label': 'new_popup'
        });
        fbq('track', 'CompleteRegistration');
      }
      this.setState({
        subscribed: true
      })
    }


  }

  render() {
    return (
      <div className="nl_pop_ext" ref="el">
        <div className="nl_pop" >
          <div className="nl_pop_body">
            <div className="nl_pop_cont">
              <div className="nl_pop_close" onClick={this.close.bind(this)} >
                <img src={icon} />
              </div>
              {this.state.subscribed ? (
                <p className="h4 body">{this.state.subscribed_copy}</p>
              ) : (
                <div>
                  <h2 className="h3 upcase c-red upcase alt_font">{this.state.t.title}<br /><br /></h2>
                  <p className="h4 body">{this.state.t.subititle}
                    <br /><br /><span className="super_small">{this.state.t.body}</span></p>
                  <Newsletter t={this.state.t} onSubscribe={this.submit.bind(this)} />
                </div>
              )}

            </div>
          </div>
        </div>
      </div>
    )
  }
}