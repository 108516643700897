import { qs, qsa, setBodyFixed, map, isMobile } from '../utils/lib'
import { gsap, Expo } from 'gsap'
import Verge from 'verge';


export default function() {
	const images = qsa('.js-lazy.not_loaded')
		images.forEach((el) => {
			if ( Verge.inY(el) && getComputedStyle(el.parentNode, null).display != 'none' ) {
				el.onload = function() {
					el.classList.remove("not_loaded")
					el.classList.add("loaded")
				}
				el.src = el.getAttribute("alt-src")
			}
	})
	const videos = qsa('.video-lazy.not_loaded')
		videos.forEach((el) => {
			if ( Verge.inY(el) ) {
				el.classList.remove("not_loaded")
				el.classList.add("loaded")
				el.querySelector('source').src = el.querySelector('source').getAttribute("alt-src")
				el.load()
			}
	})
}