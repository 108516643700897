import React, { Component } from 'react'
import icon from '../images/icon.svg'
import { fetchJSON, setCookie } from '../utils/lib'
import countryList from 'react-select-country-list'

export default class StockWidget extends Component {

  state = {
    form_status: 'disabled',
    form_text: 'Subscribe and see',
    form_checked: false,
    country: null,
    countries: countryList().getData(),
    error: ''
  }

  componentDidMount() {
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  async toggleCheck(e) {
    e.target.classList.toggle('checked')
    if (e.target.classList.contains('checked')) {
      await this.setState({
        form_checked: true
      })
    } else {
      await this.setState({
        form_checked: false
      })
    }
    this.handleChange()
  }

  handleChange() {
    const mail = this.validateEmail(this.refs.email.value)
    const check = this.state.form_checked
    if (mail && check) {
      this.setState({ form_status: 'enabled' })
    } else {
      this.setState({ form_status: 'disabled' })
    }

  }

  submit(e) {
    e.preventDefault()
    e.stopPropagation()
    if (this.state.form_status == 'disabled') {
      const mail = this.validateEmail(this.refs.email.value)
      const check = this.state.form_checked


      if (!check) {
        this.setState({
          error: 'Please accept privacy'
        })
      }
      if (!mail) {
        this.setState({
          error: 'Missing or invalid e-mail'
        })
      }
      return
    }
    const AUTH_TOKEN = document.querySelector('meta[name=csrf-token]').getAttribute('content');
    const url = '/eu/en/stock_request';
    const post = {
      "email": this.refs.email.value,
      "product": this.props.product,
      "product_id": this.props.product_id,
      "link": this.props.link,
      "authenticity_token": AUTH_TOKEN
    }

    fetchJSON(url, {
      method: 'POST',
      body: JSON.stringify(post),
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'xmlhttprequest' /* XMLHttpRequest is ok too, it's case insensitive */
      }
    })
    this.setState({
      form_status: 'disabled',
      error: ''
    })

    this.refs.email.value = 'Voilà'
    if (this.props.onSubscribe) {
      this.props.onSubscribe()
    } else {
      if (typeof gtag !== 'undefined') {
        gtag('event', 'submit', {
          'event_category': 'newsletter',
          'event_label': 'footer'
        });
        fbq('track', 'CompleteRegistration');
      }
    }
  }

  async setCountry() {
    await this.setState({
      country: this.refs.country.value
    })
    this.handleChange()
  }

  render() {
    return (
      <div className="newsletter_widget">
        <div className="newsletter_widget_row top stock">
          <button className="newsletter_widget_row_icon">
            <img src={icon} />
          </button>
          <div className="newsletter_widget_row_input full">
            <input className="h4" type="text" ref="email" onChange={this.handleChange.bind(this)} placeholder={this.props.translations.notify} />
          </div>
        </div>
        <div className="newsletter_widget_row">
          <span className="newsletter_widget_row_checkbox col-span" onClick={this.toggleCheck.bind(this)}></span>
          <p className="h4 col-flex"><a href="https://www.iubenda.com/privacy-policy/23570343/legal" target="_blank" className="default_link">{this.props.translations.accept}</a></p>
          <button className={"was_btn small"} onClick={this.submit.bind(this)}>
            <span data-text="Ok" >Ok</span>
          </button>
          <p className="nl_error upcase h4">{this.state.error}</p>
        </div>

      </div >
    )
  }
}