import React, { Component } from 'react'
import { qs, qsa, setBodyFixed } from '../utils/lib'
import { gsap, Expo } from 'gsap'
import Store from './store'
import dropdown from '../images/dropdown.svg'
import cta from '../images/cta.svg'
import WishList from './wishlist_star'

export default class ProducCart extends Component {


  state = {
    ...Store.getProps(['cart_count']),
    ...Store.getProps(['cart_open']),
    ...Store.getProps(['cart_items']),
    ...Store.getProps(['close_cart']),
    ...Store.getProps(['cart_total']),
    ...Store.getProps(['checkout']),
    add_cta: this.props.t.add,
    added_cta: "Voilà",
    stock_cta: "Out of stock",
    cart_cta: this.props.t.add,
    quantity: 1,
    current_size: 0,
    disabled: true
  }

  async componentDidMount() {
    console.log(this.props.t)
    Store.linkState(this, ['cart_count'])
    Store.linkState(this, ['cart_open'])
    Store.linkState(this, ['cart_items'])
    Store.linkState(this, ['close_cart'])
    Store.linkState(this, ['cart_total'])
    Store.linkState(this, ['checkout'])
    if (localStorage.getItem('cart')) {
      await this.setState({
        cart_items: JSON.parse(localStorage.getItem('cart'))
      })
      Store.set("cart_items", this.state.cart_items)
    }
    let c_variant = this.props.product.sizes.filter((e) => e.qty > 0)[0]
    if (c_variant && this.props.product.sizes.length == 1) {
      this.refs.select.value = c_variant.size
      this.setState({
        disabled: false,
        current_size: this.props.product.sizes.findIndex(x => x.size === c_variant.size)
      })
    }
  }

  async setSize(el) {
    this.refs.select.classList.remove("c-red")
    this.refs.size_please.classList.remove("visible")
    await this.setState({
      disabled: false,
      current_size: this.props.product.sizes.findIndex(x => x.size === el.target.value)
    })
  }

  async addToCart(e) {
    if (this.state.disabled) {
      this.refs.size_please.classList.add("visible")
    }
    if (this.state.quantity === 0 || this.state.disabled || this.props.product.sizes[this.state.current_size].qty == 0) return

    this.setState({
      cart_cta: this.state.added_cta
    })

    if (typeof gtag !== 'undefined') {
      gtag('event', 'add_to_cart', {
        "items": [this.props.product.to_google]
      });
      gtag('event', 'add', {
        'event_category': 'cart'
      });
    }


    let itemToAdd = {
      "id": this.props.product.product_id,
      "size": this.props.product.sizes[this.state.current_size],
      "title": this.props.product.title,
      "price": this.props.product.item_price,
      "compare_price": this.props.product.compare_price,
      "image": this.props.product.image,
      "quantity": this.state.quantity,
      "to_google": this.props.product.to_google
    }


    let cartItems = this.state.cart_items


    if (cartItems.length > 0) {

      let ext = cartItems.find(element => element.size.id == itemToAdd.size.id);
      if (ext) {
        ext.quantity = itemToAdd.quantity + ext.quantity
      } else {
        cartItems.push(itemToAdd)
      }
    } else {
      cartItems.push(itemToAdd)
    }

    cartItems.reverse()

    this.setState({
      cart_items: cartItems
    })
    Store.set("cart_items", cartItems)
    Store.set("add_to_cart", true)
    Store.set("cart_total", this.state.cart_total + this.state.quantity)
    localStorage.setItem('cart', JSON.stringify(this.state.cart_items));

    setTimeout(() => {
      this.setState({
        cart_cta: this.state.add_cta
      })
      Store.set("close_cart", true)
    }, 3000)
  }

  focus() {
    this.refs.select.classList.add("c-red")
  }

  removeWish() {

  }

  render() {
    return (
      <div className="product_add_to_cart">
        <div className="row">
          <div className="product_add_to_cart_image">
            <a href={this.props.product.slug}>
              <img src={this.props.product.image} />
            </a>
          </div>
          <div className="product_add_to_cart_specs">
            {this.props.remove_from_wish &&
              <div className="mb-2 text-right" onClick={this.props.remove}>
                <span className="h3 pointer c-red" >x</span>
              </div>
            }
            <a href={this.props.product.slug} className="c-red h4 pr-2">{this.props.product.title}</a>

            {(this.props.product.compare_price && parseInt(this.props.product.compare_price) > parseInt(this.props.product.item_price)) ? (
              <p className="h4">
                <span className="through with_margin">
                  € {this.props.product.compare_price}
                </span>
                € {this.props.product.item_price}
              </p>
            ) : (
              <p className="h4">

                € {this.props.product.item_price}
              </p>
            )}



            <div className="product_add_to_cart_specs_select">
              <select name="size" className="h4" ref="select" onChange={this.setSize.bind(this)}>

                <option disabled selected>{this.props.t.size}</option>
                {this.props.product.sizes.map((el, i) => (
                  <option value={el.size} disabled={el.qty < 1 ? true : null}>{el.size.replace("00", "One Size")}</option>
                ))}
              </select>
              <img src={dropdown} alt="dropdown" />
            </div>
            <p className="c-red size_please h4" ref="size_please" onClick={this.focus.bind(this)}>Size please?</p>

            <button className="was_btn small fixed_size" onClick={this.addToCart.bind(this)}>
              <span data-text={this.state.cart_cta} >{this.state.cart_cta}</span>
            </button>
          </div>
        </div>
      </div>

    )
  }
}
