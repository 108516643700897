import React from 'react'
import { render } from 'react-dom'

export default class defineComponents {

	components_loaded   = 0
	to_render 			= []
	loaded = false

	constructor(list, onLoad) {
		this.list = list
		this.onLoad = onLoad
		this._init()
	}


	_init() {
		Object.keys(this.list).forEach(this._loadComponent.bind(this))
	}

	_trackDidMountFn(ReactComponent, selector, scope = null) {
		const old_callback = ReactComponent.prototype.componentDidMount

		const that = scope || this
		ReactComponent.prototype.componentDidMount = function (e) {
			that.components_loaded += 1
			that._checkIsLoaded()

			if ( typeof old_callback === "function" )
				old_callback.call(this)
		}
	}

	_loadComponent(selector) {
		const { ReactComponent, defaultProps } = this._getElement( this.list[selector] )
		const domElements = Array.from(document.querySelectorAll(selector))

		this._trackDidMountFn(ReactComponent, selector)

		domElements.forEach(element => {
			const dataset = element.dataset || {}
			const new_dataset  = Object.assign(defaultProps, dataset)
			const reactElement = React.createElement(ReactComponent, dataset, null)

			this.to_render.push([ reactElement, element ])
		})
	}

	_renderComponents() {
		this.to_render.forEach( el => {
			render(el[0], el[1])
		})
	}

	_getElement(element) {
		if ( Array.isArray(element) ) {
			return {
				ReactComponent: element[0],
				defaultProps: element[1]
			}
		} else {
			return { ReactComponent: element, defaultProps: {} }
		}
	}

	_checkIsLoaded() {
		if ( this.components_loaded === this.to_render.length ) {
			if ( typeof this.onLoad === "function" ) {
				this.onLoad()
				this.components_loaded = 0
				// console.log('loaded')
			}
		}
	}


	load() {
		this._renderComponents()
	}
}