import Marquee3k from 'marquee3000';
import { qsa } from '../utils/lib'
export default function() {

	
	 Marquee3k.init({
	        selector: 'js_marquee'
	    });
	 setTimeout(function () {
        Marquee3k.refreshAll();
    }, 1000);
}