

import '../../scss/application.scss'
import Slider from '../components/slider.js';
import productImage from '../components/product_image.js';
import { qs, qsa, setBodyFixed } from '../utils/lib'
import defineComponents from '../utils/define.jsx'
import Minicart from '../components/minicart.jsx';
import AddToCart from '../components/add_to_cart.jsx';
import TextAnimation from '../components/text_animation.js'
import ProductCategory from '../components/product_category.js'
import Scroll from '../components/scroll.js'
import Accordion from '../components/accordion.js'
import Loader from '../components/loader.js'
import Tabs from '../components/react_tabs'
import MenuMobile from '../components/menu_mobile.js'
import WishList from '../components/wishlist_render.jsx'
import productFilters from '../components/product_filters.js'
import miniCartList from '../components/mini_cart_list.jsx'
import PX from '../components/parallax.js'
import Nl from '../components/nl_widget.jsx'
import Autocomplete from '../components/autocomplete'
import NlPop from '../components/nl_pop.jsx'
import Gift from '../components/gift'

document.addEventListener('DOMContentLoaded', () => {

  const returner = qsa('.returner')

  returner.forEach((el) => {
    el.addEventListener("change", () => {
      const target = qs(el.dataset.target);
      const value = el.value
      console.log(value)
      if (value == "false") {
        target.classList.remove("visible")

      } else if (value == "exchange") {
        target.classList.add("visible")
        target.classList.add(value)
        target.classList.remove("return")

      } else {
        target.classList.add("visible")
        target.classList.remove("exchange")
      }
    })
  })

  const return_submit = qs('.js_return_submit')
  if (return_submit) {
    return_submit.addEventListener("click", (e) => {
      e.preventDefault()
      let data = {}
      data["order_id"] = qs('.order_id').value
      data["returns"] = []
      const els = qsa(".item_input")
      els.forEach((el) => {
        const status = el.querySelector("input[type=radio]:checked").value == "false"
        if (!status) {
          let item = {}
          item["cart_item"] = JSON.parse(el.dataset.el)
          item["quantity"] = el.querySelector(".item_quantity").value
          item["reason"] = el.querySelector(".item_reason").value
          item["return"] = el.querySelector("input[type=radio]:checked").value
          item["exchange_item"] = el.querySelector("textarea").value
          data["returns"].push(item)
        }


      })
      async function postData(url = "", data = {}) {
        // Default options are marked with *
        const response = await fetch(url, {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: "follow", // manual, *follow, error
          referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify(data), // body data type must match "Content-Type" header
        });
        return response.json(); // parses JSON response into native JavaScript objects
      }

      const url = "/eu/en/order/return"
      postData(url, { return: data }).then((data) => {
        window.location.href = "/eu/en"
      });

      return false
    })
  }
  const update_cart = qs('.update_cart')
  if (update_cart) {
    async function postData(url = "", data = {}) {
      // Default options are marked with *
      const response = await fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      });
      return response.json(); // parses JSON response into native JavaScript objects
    }

    const url = update_cart.dataset.url
    const old_data = localStorage.getItem('cart')
    postData(url, { cart: old_data }).then((data) => {
      localStorage.setItem('cart', JSON.stringify(data))
    });

    return false


  }


  const App = function () {
    // Scritta bottone add to trolley >> Voila , durata pochi secondi
    PX()
    TextAnimation()
    ProductCategory()
    Scroll()
    Loader()
    MenuMobile()
    const sliders = qsa('.js-slider')
    sliders.forEach((slider) => {
      const sl = new Slider(slider)
      sl.init()
    })

    const menu_items = qsa('.js-set-menu-image')
    menu_items.forEach((el) => {
      el.addEventListener("mouseover", () => {
        const img = el.getAttribute("data-image")
        const target = qs('.old_menu-image')
        target.onload = function () {
          target.classList.add("visible")
        }
        target.src = img
      })

    })

    const country_pop = qs('.country_pop')

    if (country_pop) {
      const langs = qsa('.js-ls')
      const change_btn = qs('.js_change_country')
      const close_els = qsa('.country_close')
      const eu = JSON.parse(country_pop.dataset.eu)
      const select = qs('.country_selector')
      const notice = qs('.country_pop_notice')
      console.log("ciao")
      const in_eu = eu.includes(select.value)
      if (in_eu) {
        notice.classList.remove("visible")
      } else {
        notice.classList.add("visible")
      }
      select.addEventListener("change", () => {
        const in_eu = eu.includes(select.value)
        if (in_eu) {
          notice.classList.remove("visible")
        } else {
          notice.classList.add("visible")
        }
      })
      change_btn.addEventListener("click", () => {
        const index = country_pop.querySelector('select').options.selectedIndex
        const target = country_pop.querySelector('select').options[index].dataset.target
        window.location = target;
      })
      langs.forEach((l) => {
        l.addEventListener("click", () => {
          country_pop.classList.add("visible")
          const container = qs('.mobile_menu')
          const submenu = container.querySelectorAll('.mobile_menu_container.closed')
          const html = qs('html')
          container.classList.remove('opened')
          container.classList.add('closed')
          html.style.overflow = 'auto'
          submenu.forEach((menu) => {
            menu.classList.remove('opened')
            menu.classList.add('closed')
          })
        })
      })
      close_els.forEach((el) => {
        el.addEventListener("click", () => {
          country_pop.classList.remove("visible")
        })
      })

    }
    const bank = qs('.js_bank_pop')
    if (bank) {
      bank.addEventListener("click", function () {
        qs('.pop_bank').classList.add('open')
      })
      const bank_close = qs('.js_bank_pop_close')
      bank_close.addEventListener("click", function () {
        qs('.pop_bank').classList.remove('open')
      })
    }
    const auto = qs('.autocomplete')
    if (auto) {
      const ac = new Autocomplete(auto);
      ac.init()
    }

    const ggg = qs('.js-gift-opener')
    if (ggg) {
      const gift = new Gift(ggg);
      gift.init()
    }

    const sel = qsa('.js_redirect_select')
    sel.forEach((sel) => {
      sel.addEventListener("change", () => {
        window.location = sel.value
      })
    })
    const survey = qs('.survey')
    if (survey) {
      const btn = qs('.survey_submit')
      const checkForm = function () {
        const els = qsa(".valid")
        if (els.length == 4) {
          btn.classList.remove("disabled")
        }
      }
      const select = survey.querySelector("select")
      select.addEventListener("change", function () {
        select.classList.add("valid")
        checkForm()
      })
      const emo = qsa('.survey_emoji')
      emo.forEach((el) => {
        const target = qs("#" + el.dataset.target)
        let selected;
        const btns = el.querySelectorAll('.survey_option')
        btns.forEach((btn, i) => {
          btn.addEventListener("click", () => {
            selected = i
            applyOption()
            checkForm()
          })
        })
        const applyOption = function () {
          target.value = selected
          btns.forEach((btn) => {
            btn.classList.remove("selected")
            btn.classList.remove("valid")
          })
          btns[selected].classList.add("selected")
          btns[selected].classList.add("valid")
        }
      })

    }

    const swap_images = qsa('.js-swap-image')
    swap_images.forEach((el) => {
      el.addEventListener("mouseover", () => {
        const img = el.getAttribute("data-image")
        const target = qs('.js-swap-image-target')
        target.onload = function () {
          target.classList.add("active")
        }
        target.src = img
      })

    })



    const sdd_button = document.querySelector('.sdd_button')
    if (sdd_button) {

      sdd_button.addEventListener("click", function () {
        if (sdd_button.classList.contains('resetter')) {
          const btns = Array.from(document.querySelectorAll('.boolean_button'))
          btns.forEach(function (b) {
            b.classList.remove('active')
          })
        }
        sdd_button.classList.toggle("active")
        document.querySelector('.sdd_options').classList.toggle('active')
      })
    }

    const different = qs('.order_different_billing')
    if (different) {
      different.addEventListener('change', function () {
        const target = qs(".js-billing ")
        target.classList.toggle("hidden")
      })
    }
    const acc = qsa('.js-accordion')
    if (acc) {
      acc.forEach((content) => {
        const accordion = new Accordion(content)
        accordion.init()
      })

    }
    const size_guide_btns = qsa('.js-size-guide')
    size_guide_btns.forEach((btn) => {
      const size_guide_container = qs('.size_guide')
      if (btn) {
        btn.addEventListener('click', () => {
          setBodyFixed(1);
          size_guide_container.classList.add('open')
          const exit = size_guide_container.querySelector('.size_guide_container_close')
          if (exit) {
            exit.addEventListener('click', () => {
              setBodyFixed();
              size_guide_container.classList.remove('open')
            })
          }
        })
      }
    })


    const product_image = qs('.js-product-image')
    if (product_image) {
      const pi = new productImage(product_image)
      pi.init()
    }


    const submenu = qsa('.js-menusubnav')
    submenu.forEach((el) => {
      el.addEventListener("click", () => {
        const btn_target = el.getAttribute('target')
        const menus = qsa('.js-menu')
        menus.forEach((menu) => {
          const close = menu.querySelector('.menu_close')
          const attr = menu.getAttribute('target')
          if (btn_target == attr) {
            menu.classList.toggle("open")
          } else {
            menu.classList.remove("open")
          }
          if (menu.classList.contains('open')) {
            if (close) {
              console.log(menu)
              close.addEventListener('click', () => {

                console.log(menu, 'remove open')
                menu.classList.remove('open')
              })
            }

          }
        })


      })
    })

    const search_btns = qsa('.js-search')
    const search_menu = qs('.search_container')
    search_menu.classList.remove('open')

    if (search_btns) {
      search_btns.forEach((search) => {
        search.addEventListener('click', () => {
          search_menu.classList.toggle('open')
          const close_menu_btns = search_menu.querySelectorAll('.js-close')
          if (close_menu_btns) {
            close_menu_btns.forEach((btn) => {
              btn.addEventListener('click', () => {
                search_menu.classList.remove('open')
              })
            })

          }
        })
      })

    }

    const SetHeaderToCheckout = (el) => {
      el.value = localStorage.getItem('cart')
      if (JSON.parse(el.value).length == 0) {
        window.location = '/'
      }
    }


    const quick = document.querySelector('.js-quick-add')
    if (quick) {
      quick.addEventListener("click", function (e) {
        e.preventDefault()
        let cart_items = JSON.parse(localStorage.getItem('cart'))
        cart_items.push(JSON.parse(quick.dataset.item))
        localStorage.setItem('cart', JSON.stringify(cart_items));
        const cart_el = document.querySelector('#order_cart')
        SetHeaderToCheckout(cart_el)
        const form = document.querySelector('.simple_form')
        form.submit()
      })
    }

    const cart_el = document.querySelector('#order_cart')
    if (cart_el) {
      SetHeaderToCheckout(cart_el)
    }

    const thanks = qs('.thank_you')
    if (thanks) {
      localStorage.clear()
    }

    const filters = qs('.js-filters')
    if (filters) {
      const filter = new productFilters(filters)
      filter.init()
    }


    new defineComponents({
      '.newsletter_popup': NlPop,
      '.c-minicart': Minicart,
      '.js-add_to_cart': AddToCart,
      '.react_tabs': Tabs,
      '.wishes': WishList,
      '.mini_cart_list': miniCartList,
      '.js-newsletter': Nl
    }, () => {
    }).load()
  }

  App();

})


