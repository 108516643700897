import { qs,qsa } from '../utils/lib'
import gsap from 'gsap'


export default function() {
	const icon = qs('.js-mobile-menu')
	const container = qs('.mobile_menu')
	const submenu = container.querySelectorAll('.mobile_menu_container.closed')	
	const html = qs('html')
	if (container,icon) {
		icon.addEventListener('click', () => {
			if (!container.classList.contains('opened')){
				container.classList.add('opened')
				container.classList.remove('closed')
				html.style.overflow = 'hidden'
				const btns = container.querySelectorAll('button')
				btns.forEach((btn) => {
					btn.addEventListener('click', () => {
						const attribute = btn.getAttribute('data-target')
						submenu.forEach((menu) => {
							const menu_attribute = menu.getAttribute('data-target')
							if (menu_attribute == attribute) {
								menu.classList.add('opened')
								menu.classList.remove('closed')
								html.style.overflow = 'hidden'
								if (menu.classList.contains('opened')) {
									const close_btns = menu.querySelectorAll('.close_sub')
									close_btns.forEach((btn) => {
										btn.addEventListener('click', () => {
											menu.classList.add('closed')
											menu.classList.remove('opened')
										})
									})
								}
							}
						})												
					})
				})
			} else {
				container.classList.remove('opened')
				container.classList.add('closed')
				html.style.overflow = 'auto'				
				submenu.forEach((menu) => {
					menu.classList.remove('opened')
					menu.classList.add('closed')					
				})								
			}

		})	
	}
}