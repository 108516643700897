import React, { Component } from 'react'
import { qs, qsa, setBodyFixed } from '../utils/lib'
import { gsap, Expo } from 'gsap'
import Store from './store'
import Tabs from './react_tabs'
import Share from './share'
import Wishlist from './wishlist'
import StockWidget from './stock_widget.jsx'

export default class ProductImage extends Component {

  state = {
    product: JSON.parse(this.props.product),
    ...Store.getProps(['cart_count']),
    ...Store.getProps(['cart_open']),
    ...Store.getProps(['cart_items']),
    ...Store.getProps(['close_cart']),
    ...Store.getProps(['cart_total']),
    ...Store.getProps(['checkout']),
    add_cta: "Add to Trolley",
    added_cta: "Voilà",
    stock_cta: "Out of stock",
    cart_cta: "Add to Trolley",
    looks: JSON.parse(this.props.looks),
    quantity: 1,
    current_size: 0,
    t: JSON.parse(this.props.translations)
  }

  async componentDidMount() {

    let c_variant = this.state.product.sizes.filter((e) => e.qty > 0)[0]
    if (c_variant) {
      this.setState({
        current_size: this.state.product.sizes.indexOf(c_variant)
      })
    } else {
      this.checkSize()
    }
    if (this.props.preorder == "true") {
      this.setState({
        cart_cta: this.state.t.pre
      })
    } else {
      this.setState({
        cart_cta: this.state.t.add
      })
    }


    const closeLooks = qs('.looks_modal')
    closeLooks.addEventListener("click", function (e) {
      const pop = qs('.looks_modal')
      pop.classList.remove("open")
    })
    if (this.props.stock > 0) {
      const btn = this.refs.size_btn
      btn.addEventListener("click", function () {
        const sizeclass = ".sizeguide_" + btn.dataset.size
        const size_guide = qs('.size_guide')
        const size_el = size_guide.querySelector(sizeclass)
        setBodyFixed(1);
        size_guide.classList.add("open")
        if (size_el) {
          size_guide.querySelector(sizeclass).classList.add("active")
        }
      })
      const close = qs('.size_guide_container_close')
      close.addEventListener("click", function () {
        setBodyFixed();
        const size_guide = qs('.size_guide')
        size_guide.classList.remove("open")
      })
    }

    Store.linkState(this, ['cart_count'])
    Store.linkState(this, ['cart_open'])
    Store.linkState(this, ['cart_items'])
    Store.linkState(this, ['close_cart'])
    Store.linkState(this, ['cart_total'])
    Store.linkState(this, ['checkout'])
    if (localStorage.getItem('cart')) {
      await this.setState({
        cart_items: JSON.parse(localStorage.getItem('cart'))
      })
      Store.set("cart_items", this.state.cart_items)
    }
  }

  checkSize() {
    if (this.props.preorder == "true") {
      this.setState({
        cart_cta: this.state.t.pre
      })
    } else {
      this.setState({
        cart_cta: this.state.t.add
      })
    }
    const size = this.state.product.sizes[this.state.current_size].qty <= 0
    console.log(size)
    if (size) {
      this.setState({
        cart_cta: this.state.stock_cta
      })
    } else {
      this.setState({
        cart_cta: this.state.add_cta
      })
    }

  }

  async setSize(el) {
    await this.setState({
      current_size: this.state.product.sizes.findIndex(x => x.size === el.target.value),
      quantity: 1
    })

    this.checkSize()
  }
  async addToCart(e) {
    if (this.state.quantity === 0 || this.state.product.sizes[this.state.current_size].qty == 0 || this.state.product.sizes[this.state.current_size].qty < 0) return

    if (typeof gtag !== 'undefined') {
      gtag('event', 'add_to_cart', {
        "items": [this.state.product.to_google]
      });
      gtag('event', 'add', {
        'event_category': 'cart'
      });
    }



    let itemToAdd = {
      "id": this.state.product.product_id,
      "size": this.state.product.sizes[this.state.current_size],
      "title": this.state.product.title,
      "price": this.state.product.item_price,
      "compare_price": this.state.product.compare_price,
      "image": this.state.product.image,
      "quantity": this.state.quantity,
      "to_google": this.state.product.to_google
    }


    let cartItems = this.state.cart_items


    if (cartItems.length > 0) {

      let ext = cartItems.find(element => element.size.id == itemToAdd.size.id);
      if (ext) {
        if (ext.quantity == this.state.product.sizes[this.state.current_size].qty) {
          this.setState({
            cart_cta: "No more stock"
          })
          return
        }
        ext.quantity = itemToAdd.quantity + ext.quantity
      } else {
        cartItems.push(itemToAdd)
      }
    } else {
      cartItems.push(itemToAdd)
    }



    cartItems.reverse()

    this.setState({
      cart_items: cartItems
    })
    Store.set("cart_items", cartItems)
    Store.set("add_to_cart", true)
    Store.set("cart_total", this.state.cart_total + this.state.quantity)
    localStorage.setItem('cart', JSON.stringify(this.state.cart_items));

    setTimeout(() => {
      this.setState({
        cart_cta: this.state.add_cta
      })
    }, 3000)
  }

  less() {
    if (this.state.quantity == 1) {
      return
    }
    this.setState({
      quantity: this.state.quantity -= 1
    })
  }

  more() {
    if (this.state.quantity + 1 > this.state.product.sizes[this.state.current_size].qty) {
      return
    }
    this.setState({
      quantity: this.state.quantity += 1
    })
  }

  openlooks() {
    const pop = qs('.looks_modal')
    pop.classList.add("open")
  }


  render() {
    return (
      <div>
        {this.props.stock > 0 && !this.props.gift && (

          <div className="row flex-middle">
            <p className="col col-20 xs_auto">{this.state.t.size} :</p>
            <div className="col col-flex xs_50">
              <div className="was_select">
                <select onChange={this.setSize.bind(this)} value={this.state.product.sizes[this.state.current_size].size}>
                  {this.state.product.sizes.map((el, i) => (
                    <option key={i} value={el.size} disabled={el.qty < 1 ? true : null}>{el.size.replace('00', 'One Size')}</option>
                  ))}

                </select>
              </div>
              <br />
              <button className="mt-h" data-size={this.state.product.size_chart} ref="size_btn"><p className="default_link">{this.state.t.size_guide}</p></button>
            </div>

          </div>

        )}
        {this.props.stock > 0 && (
          <div className={"product_detail_col_container_description " + (this.state.product.sizes[this.state.current_size].qty == 0 ? "disabled" : "")}>
            <p className="col col-20">{this.state.t.quantity}:</p>
            <div className="quantity">
              <span className="minus noselect" onClick={this.less.bind(this)}>-</span>
              <span className="number noselect">{this.state.quantity}</span>
              <span className="plus noselect" onClick={this.more.bind(this)}>+</span>
            </div>
          </div>

        )}
        <div className="product_detail_col_container_actions mb-4">
          <div className="row compact">
            {this.props.stock > 0 && (
              <div className="col col-50 compact">
                <button className="was_btn block" onClick={this.addToCart.bind(this)}>
                  <span data-text={this.state.cart_cta} >{this.state.cart_cta}</span>
                </button>
              </div>
            )}
            {(this.props.stock > 0 && this.state.product.sizes[this.state.current_size].qty > 0 && this.state.product.sizes[this.state.current_size].qty < 2) && !(this.props.preorder == 'true') && (
              <div className="col col-100 compact">
                <div className="stock_remain text-center">
                  <div className="stock_remain_bar">
                    <div className="stock_remain_bar_thumb" style={{ width: this.state.product.sizes[this.state.current_size].qty + "%" }}></div>
                  </div>
                  <p>{this.state.t.only} <strong>{this.state.product.sizes[this.state.current_size].qty}</strong> {this.state.t.left} </p></div>
              </div>
            )}
            {this.props.stock < 1 && !this.props.gift && (
              <button className="was_btn small">
                <span data-text="SOLD OUT" >SOLD OUT</span>
              </button>
            )}



          </div>
        </div>

        {this.props.stock < 1 && !this.props.gift && (
          <>

            <StockWidget
              product={this.state.product.title}
              product_id={this.state.product.product_id}
              link={this.state.product.slug}
              translations={this.state.t}
            />
            <a className="c-red upcase base_size" href={this.props.breadcrumb}>{this.props.cta} ></a>
            <br /><br />
          </>
        )}
        <Tabs tabs={this.props.tabs} t={this.state.t} />
        <div className="product_detail_col_container_links">
          {this.state.looks.length > 0 &&
            <button className="default_link h3 upcase alt_font c-red" onClick={this.openlooks.bind(this)}>{this.state.t.how_to_wear} </button>
          }
          <Share />
        </div>

      </div>

    )
  }
}
