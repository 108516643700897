import { gsap, Power4 } from 'gsap'
import { qs,qsa } from '../utils/lib'
import Hammer from 'hammerjs'

export default class Slider {
    constructor(el) {
        this.wrapper = el
        this.images  = qs('.slider_container',el)
        this.all_images = qsa('.product_big_img',el)
        this.total = this.all_images.length
        this.btn_next = qs('.js-next',el)
        this.btn_prev = qs('.js-prev',el)
        this.active = 0
        this.direction = 0
        this.page = this.wrapper.getAttribute("data-page") || 3
        this.limit = Math.ceil(this.total / this.page)
        this.image_width = this.all_images[0].offsetWidth
        this.scaled = this.wrapper.getAttribute("data-scaled") || false

    }

    init() {
        if ( this.scaled) {
            this.animate()
        }
        if ( window.innerWidth < 1024 ) {
            if ( !this.wrapper.classList.contains('product_sl')) {
                this.page = 2 
            }
        }
        const manager = new Hammer.Manager(this.wrapper);
        const Swipe = new Hammer.Swipe({
            direction: 6,
            velocity: .2
        });
        manager.add(Swipe);
        manager.on('swipeleft', () => {
           this.next()
        });
        manager.on('swiperight', () => {
          this.prev()
        });
       
        this.btn_next.addEventListener("click",() => {
            this.next()
        })
        this.btn_prev.addEventListener("click",() => {
            this.prev()
        })

        window.addEventListener('resize', () => {
            
            this.image_width = this.all_images[0].offsetWidth
        
        })
    }


    next() {
            if ( this.all_images.length == 1 ) {
                return
            }
            let new_active = 0
            if ( (this.page  == this.total - this.active) ) {
                new_active = 0
            } else {
                new_active = this.active + 1
            }
            if ( new_active > this.active ) {
                this.direction = -1 
            } else {
                this.direction = 1
            }
            this.active = new_active
            this.animate()
    }

    prev() {
            if ( this.all_images.length == 1 ) {
                return
            }
            let new_active = 0
            if ( this.active  == 0 ) {
                new_active = this.total - this.page
                
            } else {
                new_active = this.active - 1
            }
            if ( new_active > this.active ) {
                this.direction = -1 
            } else {
                this.direction = 1
            }
            this.active = new_active
            this.animate()

    }
    animate(){
        const target = this.active * this.image_width
        if ( this.scaled ) {
            gsap.to(this.all_images[this.active],{
                scale:1,
                duration: .5
            })
            gsap.to(this.all_images[this.active + 1],{
                scale:.5,
                duration: .5
            })
             gsap.to(this.images,{
                x: -target,
                duration:1
            })
        } else {
            gsap.to(this.images,{
                x: -target,
                duration:1
            })
            gsap.to(this.images,{
                x: -target,
                duration:1
            })

            gsap.to(this.images,{
                skewX: -3 * this.direction,
                duration:.2,
                ease:Power4.easeInOut,
            })
            gsap.to(this.images,{
                skewX: 0,
                ease:Power4.easeInOut,
                duration:.5,
                delay: .5
            })
        }
        
    }
}