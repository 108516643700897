import { qs, qsa } from '../utils/lib'
import gsap from 'gsap'

export default class GiftOptions {
  constructor(el) {
    this.is_open = false
    this.button = el
    this.body = qs('.js-gift-target')
    this.options = qsa('.js-gift')
    this.option = qs('#order_gift_option')
  }

  init() {
    if ( this.button.dataset.open == 'true' ) {
      this.is_open = true 
    }
    this.button.addEventListener("click",() => {
      if ( this.is_open ) {
        this.close()
      } else {
        this.open()
      }
    })
    this.options.forEach((btn,i) => {
      btn.addEventListener("click",() => {
        this.removeClasses()
        this.option.value = btn.dataset.name
        btn.classList.add('selected')
      })
    })
  }

  removeClasses() {
    this.options.forEach((btn,i) => {
      btn.classList.remove('selected')
    })
  }
  open() {
    this.is_open = true
    this.body.classList.add("visible")
  }
  close() {
    this.is_open = false
    this.body.classList.remove("visible")
    this.removeClasses()
    this.option.value = ""

  }

 
}